<template>
  <div class="main-wrapper">
    <template>
     <loader v-if="isLoaded"
     object="#3370b7" 
     color1="#ffffff" 
     color2="#17fd3d" 
     size="5"
     speed="2" 
     bg="#ffffff" 
     objectbg="#cfcfcf" 
     opacity="80"
      disableScrolling="false"
       name="spinning"></loader>
    </template>
    <div class="mt-4">

    </div>
    <div class="row">
      <div class="col-lg-6 pl-5 pr-5" style="margin-top:65px;">
         <div class="row">
              <div class="col-md-12">
                <!-- Row With Forms -->
                <div class="row with-forms">
                  <!-- Main Search Input -->
                  <div class="col-md-6  col-sm-6 col-6">
                    <div class="form-group">
                      <label for="">ZipCode/City</label>
                      <v-select
                        v-model="zipChange"
                        :options="zipcodes"
                        placeholder="Search ZipCode/City"
                        class="zipcode"
                        @search="fetchZipCode"
                        style="cursor:pointer !important"
                      />
                    </div>
                  </div>
                  <!-- Main Search Input -->
                  <div class="col-md-6  col-sm-6 col-6">
                    <div class="form-group">
                      <label for="">Places</label>
                      <v-select
                        v-model="placeChange"
                        placeholder="Search Place"
                        class="places"
                        :options="places_list"
                        @search="fetchPlace"
                      />
                    </div>
                  </div>
                </div>
                <!-- Row With Forms / End -->
              </div>
            </div>
            <div class="row" >
              <div class="col-md-6 col-sm-6 col-6">
                <button 
                  v-if="$isAuth"
                  @click="showModel"
                  type="button" 
                  class="btn btn-danger btn-sm"
                  style="width:100%">Add Place
                </button>
                 <button 
                  v-else
                  data-toggle="modal"
                  data-target="#login" 
                  type="button" 
                  class="btn btn-danger btn-sm"
                  style="width:100%">Add Place
                </button>
              </div>
               <div class="col-md-6 col-sm-6 col-6">
                <select name="sort" id="sort" class="form-control form-control-sm" @change="sortPlaces">
                  <option disabled hidden value="" selected >Sort By </option>
                  <option value="a">Alphabetical</option>
                  <option  value="s">Distance</option>
                  <option value="likes">Most Liked </option>
                </select>
              </div>
              <div class="col-md-6  col-sm-6 col-6 ">
                <p
                  v-if="total_pages"
                  class="showing-results"
                >
                 <span style="color:#337ab7" class="font-weight-bold"> {{ total_pages }}</span> Results Found
                </p>
                <p
                  v-else
                  class="showing-results text-danger font-weight-bold"
                >
                  No Record Found
                </p>
              </div>
               <!-- <div class="col-md-3 col-sm-3 col-1 text-right">
              </div> -->
              <!-- <div class="col-md-3  col-sm-3 col-5" >
                <small class="font-weight-bold">Select Page</small>
                <select @change="pageSelect" name="" id="" class="form-control form-control-sm" v-model="page">
                  <option value="" hidden disabled selected>Select Page </option>
                  <option v-for="page_number in total_pages_number" :key="page_number" :value="page_number" >
                    {{page_number}}
                  </option>
                </select>
              </div> -->
            </div>
             <div class="row">
               <div class="col-md-12 map1" style="height:60vh">
                  <map-banner
                    v-if="coordinates"
                    :key="places"
                    type="live-music"
                    layout="sidebar"
                    :coordinates="coordinates"
                    :events="places"
                    :centerCordinates="centerCordinates"
                      style="height:100%"
                    :redoSearch="redoSearch"
                    :locateMe="myFunction"
                  />
                </div>
            </div>
            <div class="row fs-switcher pt-4 results-div">
              
            </div>
        <div class="row fs-listings"  style="min-height:55vh">
              <!-- Listing Item -->
              <div
                v-for="(list,index) in places"
                :key="index"
             
               class="col-lg-6 col-md-6 col-sm-6 grid-layout-list mb-4"
              >
                <div class="list-cap">
                  <div class="list-cap-list mb-4">
                    <router-link :to="`/happy-hour/${list.slug}`">	
                      <div
                        class="img-list"
                        style="width:100%;height:200px;"
                      >
                        <img
                          v-if="list.images[0]"
                          :src="	baseUrl + list.images[list.images.length - 1].image"
                          alt=""
                          class="img-fluid"
                          style="width:100%; height: 100%;object-fit: cover;"
                        >
                        <!-- <img
                          v-if="list.images[0]"
                          :src="	baseUrl + list.images[0].image"
                          alt=""
                          class="img-fluid"
                          style="width:100%; height: 100%;object-fit: cover;"
                        > -->
                        <img
                          v-else
                          src="/static/images/hh_place.jpg"
                          alt=""
                          class="img-fluid"
                          style="width:100%; height: 100%;object-fit: cover;"
                        >
                      </div>
                    </router-link>
                    <div class="list-cap-content list-cap-content--style">
                      <div class="row">
                        <div class="col-6">
                          <span class="badge badge-pill badge-primary text-uppercase mr-2">Happy Hour </span>
                        </div>
                        <div class="col-6 text-right">
                          <span
                            v-if="$isAuth"
                            :id="list.id"
                            style="cursor:pointer;"
                            title="Like"
                            class="round-pill like-button bg-primary"
                            @click="like(list.id)"
                            ><i class="fa fa-thumbs-up" /> {{ list.total_likes }}
                          </span>
                          <span
                            v-else
                            :id="list.id"
                            data-toggle="modal"
                            data-target="#login"
                            style="cursor:pointer;"
                            title="Like"
                            class="round-pill like-button bg-primary"
                            ><i class="fa fa-thumbs-up" /> {{ list.total_likes }}
                          </span>
                        </div>
                      </div>
                      <!-- <Rating></Rating> -->
                      <h5 class="mt-2">
                        {{ list.name }}
                      </h5>
                      <div
                        class="address-bar "
                        style="color:#525f7f;"
                      >
                        <p>{{ list.address_1 }}, {{ list.city }}, {{ list.state }} {{ list.zipcode }}</p>
                         <i class="fa fa-map-marker" aria-hidden="true" style="color:#e4486d;"></i>
                        <b style="color:#0f7acf">
                          {{ parseFloat(list.distance).toFixed(2) }} Miles
                        </b>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <!-- Listing Item / End -->
            </div>
            <pagination
                  v-if="(Object.keys(places).length > 0)"
                  v-model="page"
                  :records="Math.ceil(total_pages)"
                  :per-page="10"
                  @paginate="callback"
                />
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 map2" style="height:75vh;margin-top:10px">
            <map-banner
              name="Special"
              v-if="coordinates"
            :key="places"
              layout="sidebar"
              :coordinates="coordinates"
              :events="places"
              type='happy-hour'
              :redoSearch="redoSearch"
              style="height:100%"
              :locateMe="myFunction"
          />
          </div>
          <div class="col-md-6 p-1 ml-3" style="margin-top:140px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
            <div class="row"   >
              <div class="col-md-7">
                <img src="/static/images/zipcodepro-logo.png" alt="" style="width:100%;display:inline">
              </div>
              <div class="col-md-5"> 
                 <a href="#"   style="color:#0f7acf">	<b>Visit Directory</b> </a>
              </div>
            </div>
            <img src="/static/images/demo-ad.png" alt="" style="width:100%">
          </div>
        </div>
      </div>
    </div>
    <!-- //login modal -->
    <div
      id="login"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:90px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;padding: 6px 6px 6px 18px !important;"
          >     
            <h5 style="color:white;">
              Login
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <loginModal />
        </div>
      </div>
    </div>
    <!-- Add Place model  -->
     <div
      id="add_place"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:90px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;padding: 6px 6px 6px 18px !important;"
          >     
            <h5 style="color:white;">
              Add Place 
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <AddPlace type='hh' />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MapBanner from 'Components/globalFrontendComponents/MapBanner';
import Pagination from 'vue-pagination-2';
import vSelect from 'vue-select';
import {axios} from '../services/http-common';
import 'vue-select/dist/vue-select.css';
import MyList from 'Data/listing.json';
import loginModal from '../components/LoginModal.vue';
import AddPlace from '../components/AddPlace.vue';

export default {
	components: {
		MapBanner: MapBanner,
      vSelect:vSelect,
      Pagination:Pagination,
      loginModal,
      AddPlace,
	},
	data(){
		return{
        isLoaded:true,
         page: localStorage.getItem('hh_page') ? parseFloat(localStorage.getItem('hh_page')) : 1,
         baseUrl:process.env.VUE_APP_STORAGE_URL,
         data: MyList.data,
         places: '',
         places_list:[], 
         filterBy:'s',
         lat:'',
         lon:'',
         coordinates: '',
         centerCordinates:'',
         // zipcodes:[{label: "Houston TX 77007"}],
         zipcodes:[],
         spinner:true,
         // zipChange: 'Houston TX 77007',
         zipChange: localStorage.getItem('hh_zip') ? localStorage.getItem('hh_zip') : 'Houston-TX',
         placeChange:'',
         total_pages: '',
         total_pages_number:'',
         area: localStorage.getItem('hh_zip') ? localStorage.getItem('hh_zip') : 'Houston-TX'
		}
	},
  
   watch: {
      zipChange: function(val) {
         if (val) {
            this.page = 1;
            let area = val.label;
            this.area = area;
            if(area){
              localStorage.setItem('hh_zip',area);
               axios
            .get(`${process.env.VUE_APP_API_URL}happy-hours?orderBy=DESC&sortBy=total_likes&filter=${this.filterBy}&isWeb=1&area=`+area)
            .then(response => {
               if (!response.error) 
                  if(response.data.total_pages)
                    this.total_pages = response.data.total_pages;
                  else
                    this.total_pages = 0;
                  
                  this.places = response.data.data;
                  this.total_pages_number = Math.ceil(parseInt(this.total_pages)/10);
                  this.coordinates = response.data.coordinates;
                 
            });
            }
            else
            localStorage.setItem('hh_zip',val);
         }
      },
      placeChange: function(val) {
         let slug = val.slug;
         if (val) {
           this.$router.push({ path: '/happy-hour/'+slug})
         }
      }
  },
   async mounted() {
      var check = $('#navbar_global').hasClass('show');
      if(check)
        $('#navbar_global').removeClass('show');

      let res = await this.getPlaces();
      console.log('Resulkt is:',res);
      this.places = res.data;
      // this.total_pages = res.total_pages;
      this.coordinates = res.coordinates;
      if(res.total_pages)
        this.total_pages = res.total_pages;
      else
        this.total_pages = 0;

      this.total_pages_number = Math.ceil( this.total_pages/10);
      this.isLoaded = false;
     
   },
   methods:{
     showModel(){
      $('#add_place').modal('toggle');
      $('#add_place').find('form').trigger('reset');
      $('#blah').attr('src', '/static/images/placeholder-min.jpg');
     },
    async sortPlaces(e){
      this.filterBy = e.target.value;
      this.page = 1;
      var res = await  this.getPlaces();
      this.places = res.data;
      this.total_pages = res.total_pages;
      this.coordinates = res.coordinates;
     },
      redoSearch(){
        this.isLoaded = true;
       let lat = localStorage.getItem('lat');
       let lng = localStorage.getItem('lng');

        let page = parseFloat(this.page);
         if(this.page > 1)
            page = ( (parseFloat(this.page) -1) * 10);
         else{
            page = 0;
            }
          return axios
            .get(`${process.env.VUE_APP_API_URL}happy-hours?orderBy=DESC&sortBy=total_likes&lat=${lat}&lng=${lng}&isWeb=1&filter=${this.filterBy}&offset=${page}&type=redo_search`)
            .then(res => {
                this.places = res.data.data;
                this.total_pages = res.data.total_pages;
                this.coordinates = res.data.coordinates;
                // localStorage.setItem('hh_zip', res.data.area.city +','+ res.data.area.state);
                this.zipChange =  res.data.area.city +','+ res.data.area.state;
                this.isLoaded = false;
            });
    },
      myFunction() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.errorCallback
        );
      } else {
        this.error = "Geolocation is not supported.";
        // console.log("this.error in my function");
        this.locateMe();
      }
    },
    showPosition(position) {
      this.lat = position.coords.latitude;
      this.lon = position.coords.longitude;
      this.getLoc();
      if (
        typeof position.coords.latitude === "undefined" ||
        typeof position.coords.longitude === "undefined"
      ) {
        this.locateMe();
      }
    },
      locateMe() {
        this.isLoaded = true;
        return  axios
            .get('https://api.ipgeolocation.io/ipgeo?apiKey=fab1a7a1c31a41ee87451a0f63201f73')
            .then((response)  => {
              this.isLoaded = false;
              localStorage.setItem('lat',response.data.latitude);
              localStorage.setItem('lng', response.data.longitude);
              this.redoSearch();
            })
            .catch(function() {});
    },
    getLoc: function() {
      return axios
            .post(`${process.env.VUE_APP_API_URL}my-location`,{lat:this.lat, lon:this.lon})
            .then(res => {
              //  this.isLoaded = false;
                localStorage.setItem('lat',res.data.latitude);
                localStorage.setItem('lng', res.data.longitude);
               this.redoSearch();
            });
    },
      like(id){
         let data = {};
         data.likeable_type = 'place';
         data.likeable_id = id;
         return axios
            .post( `${process.env.VUE_APP_API_URL}likes`,data)
            .then(response => {
            if(!response.error) {
               $('#'+id).html('<i class="fa fa-thumbs-up"></i> ' + response.data.data.total_likes);
            }
         });
      },
     
      getPlaces(){
         let page = parseFloat(this.page);
         if(this.page > 1)
            page = ((parseFloat(this.page) -1) * 10);
         else{
            page = 0;
            }
         return axios
            .get( `${process.env.VUE_APP_API_URL}happy-hours?orderBy=DESC&sortBy=total_likes&filter=${this.filterBy}&isWeb=1&area=${this.zipChange}&offset=${page}`)
            .then(response => {
            if (!response.error) {
               return response.data;
            }
         });
      },
      fetchZipCode (search, loading) {
        
         if(search){
            return axios
            .get(`${process.env.VUE_APP_API_URL}filter-location/`+search+'/hh')
            .then(response => {
               if (!response.error) {
                  this.zipcodes = response.data.data;
               }
            });
         }
      },
       fetchPlace(search, loading) {
         if(search){
            return axios
            .get(`${process.env.VUE_APP_API_URL}filter-place/`+search)
            .then(response => {
               if (!response.error) {
                  this.places_list = response.data.data;
               }
            });
         }
      },
      pageSelect(e){
        this.page = e.target.value;
        this.callback();
      },
      callback: function(data) {
         this.isLoaded = true;
         localStorage.setItem('hh_page',this.page);
         let page = parseFloat(this.page);
         if(this.page > 1)
            page = ( (parseFloat(this.page) -1) * 10);
         else
            page = 0;
         axios.get(`${process.env.VUE_APP_API_URL}happy-hours?orderBy=DESC&sortBy=total_likes&filter=${this.filterBy}&isWeb=1&area=`+this.area+'&offset='+page)
         .then(response => {
            if (!response.error) {
               this.places = response.data.data;
               this.coordinates = response.data.coordinates;
               window.scrollTo(0, 0);
            }
            this.isLoaded = false;
         });
      },
   },
   updated() {
    $(".vs__dropdown-toggle").css('cursor','pointer');
    $('.gm-style > div[class=""]').css('z-index','234234342424224242423243242 !important');
    $('.gm-style > div[class=""]').addClass('test');
  }
};
  $('.vs__dropdown-toggle').css("cursor","pointer");

</script>
<style scoped>
.vs__dropdown-toggle{
  cursor: pointer !important;
}
.gm-style > div[class=""] {
  /* z-index: 234234342424224242423243242 !important; */
  border: 2px solid red;
}

@media only screen and (max-width: 990px) {
  .map2{
    display:none !important;
  }
  .map1{
    display:block !important;
  }
  .map-button{
    margin-bottom: 1024px !important;
  }
  .results-div{
    margin-top:70px;
  }
}

@media only screen and (min-width: 990px) {
  .map2{
    display:block !important;
  }
  .map-button{
    margin-bottom: 1042px !important;
  }
  .map1{
    display:none !important;
  }
}
.like-button{
    font-size: 13px;
    cursor: pointer;
    background-color: #5e72e4;
    color: #ffffff;
    padding: 12px 10px 12px 10px;
    border-radius: 50%;
}
</style>